body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiAlert-message{
    white-space: pre-line;
}

.newsletter-stat{
    flex: 1;
    display: flex;
}

.newsletter-stat .newsletter-stat__detail {
    display: flex;
    flex-direction: column;
}
.newsletter-stat .newsletter-stat__panel {
    width: 100%;
    max-width: 900px;
}
.newsletter-stat .newsletter-stat__panel__card {
    padding: 15px;
    margin: 10px;
    max-width: 50%;
    width: 300px;
}

.newsletter-stat .newsletter-stat__panel-summary {
    width: 100%;
    box-sizing: border-box;
}
